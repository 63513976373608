import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'; // New
import axios from 'axios';
import './assets/css/main.css'
import { BootstrapVueNext } from "bootstrap-vue-next"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue-next/dist/bootstrap-vue-next.css"
import '@dmuy/timepicker/dist/mdtimepicker.css'
import vueTimePicker from '@dmuy/timepicker/vue3-timepicker'
import 'bootstrap';
import 'datatables.net-bs5';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
// import $ from 'jquery'
// window.$ = $
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

const VueTelInputOptions = {
    mode: "international",
    preferredCountries: [ "us","gb"],
    defaultCountry: "US",
    required: true,
  }

const timePickerOptions = {
  theme: 'dark',
}

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'http://maxcessiot.com:5000/';  // the FastAPI backend

/* import specific icons */
import { faTachometerAlt,faChartColumn,faCalendarDays,faCalendarCheck,faStream,faSignal,faChartPie,faHistory,faRightFromBracket,faTrash,faGear,faAddressCard,faHardDrive,faScrewdriverWrench,faListCheck,faDesktop,faPeopleRoof,faUsers,faAngleUp,faAngleDown,faUserPlus,faPlus,faChevronUp,faMinus,faCircleArrowDown,faCircle,faClock,faCircleInfo,faUserShield,faLaptopCode,faBug } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faTachometerAlt,faChartColumn,faCalendarDays,faCalendarCheck,faStream,faSignal,faChartPie,faHistory,faRightFromBracket,faTrash,faGear,faAddressCard,faHardDrive,faScrewdriverWrench,faListCheck,faDesktop,faPeopleRoof,faUsers,faAngleUp,faAngleDown,faUserPlus,faPlus,faChevronUp,faMinus, faCircleArrowDown,faCircle,faClock,faCircleInfo,faUserShield,faLaptopCode,faBug)


createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(VueTelInput,VueTelInputOptions).use(BootstrapVueNext).use(router).use(store).use(vueTimePicker,timePickerOptions).mount('#app')
