<script setup>
  import $ from 'jquery'
  import { ref, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import router from '@/router';

  const store = useStore()

  var showSettings = ref(true)

  async function isConnected() {
    try{
      await store.dispatch('viewMe')
    
    }catch(err) {
      console.log("error in catch:",err)
      console.log("here1 in sidebar",store.getters.isAuthenticated)
      router.push({ name: 'Home'})
    }
  }

  function changeDropDown() {
    $("#side-settings .sidebar-dropdown-array").toggleClass("rtoate180");
  }

  onMounted(async () => {
    await isConnected();

    if(store.getters.stateUser != null){
      if(store.getters.stateUser.role.user_role == "User" || store.getters.stateUser.role.user_role == "Employee"){
        showSettings.value = false
      }
    }else{
      router.push({ name: 'Home'})
    }
    
  })

</script>


<template>
    <router-view>
    <div id="mySidebar" class="sidebar shadow sidebar-expand">
        <div class="link-wrapper">
          <span><router-link to="/dashboard" class="side-link"><font-awesome-icon :icon="['fas', 'tachometer-alt']" /> Dashboard</router-link></span>
          <span><router-link to="/deviceusage" class="side-link"><font-awesome-icon :icon="['fas', 'chart-column']" /> Device Usage</router-link></span>
          <span><router-link to="/events" class="side-link"><font-awesome-icon :icon="['fas', 'calendar-days']" /> Events</router-link></span>
          <span><router-link to="/devicestatus" class="side-link"><font-awesome-icon :icon="['fas', 'bars-staggered']" /> Device Status</router-link></span>
          <span><router-link to="/jobstatus" class="side-link"><font-awesome-icon :icon="['fas', 'calendar-check']" /> Job Status</router-link></span>
          <span><router-link to="/maintenance" class="side-link"><font-awesome-icon :icon="['fas', 'signal']" /> Maintenance</router-link></span>
          <span><router-link to="/reports" class="side-link"><font-awesome-icon :icon="['fas', 'chart-pie']" /> Reports</router-link></span>
          <span><router-link to="/jobhistory" class="side-link"><font-awesome-icon :icon="['fas', 'clock-rotate-left']" /> Job History</router-link></span>
        </div>
        
        <BCollapse id="collapse-settings"  visible="true" isNav="true">
          <template #header="{visible, toggle, id}">
            <span :aria-expanded="visible" :aria-controls="id" id="side-settings" @click="toggle(),changeDropDown()" class="side-settings side-link"><font-awesome-icon :icon="['fas', 'gear']" /> Settings <font-awesome-icon class="sidebar-dropdown-array" :icon="['fas', 'chevron-up']" /> </span>
          </template>
          <span><router-link to="/manageprofile" class="side-link setting-link"><font-awesome-icon :icon="['fas', 'address-card']" /> Manage Profile</router-link></span>
          <span v-if="showSettings"><router-link to="/managemacines" class="side-link setting-link"><font-awesome-icon :icon="['fas', 'hard-drive']" /> Machine Management</router-link></span>
          <span v-if="showSettings"><router-link to="/maintenancesettings" class="side-link setting-link"><font-awesome-icon :icon="['fas', 'screwdriver-wrench']" /> Maintenance Settings</router-link></span>
          <span v-if="showSettings"><router-link to="/eventsettings" class="side-link setting-link"><font-awesome-icon :icon="['fas', 'list-check']" /> Event Settings</router-link></span>
          <span v-if="showSettings"><router-link to="/systemsetup" class="side-link setting-link"><font-awesome-icon :icon="['fas', 'desktop']" /> System Setup</router-link></span>
          <!-- <span v-if="showSettings"><router-link to="/manageoperators" class="side-link setting-link"><font-awesome-icon :icon="['fas', 'people-roof']" /> Manage Operators</router-link></span> -->
          <span v-if="showSettings"><router-link to="/manageusers" class="side-link setting-link"><font-awesome-icon :icon="['fas', 'users']" /> Manage Users</router-link></span>
        </BCollapse>
      </div>
    </router-view>
</template>

<script>



  export default {
    name: 'SidebarComponent',
    components: {
      
    }
  }

</script>