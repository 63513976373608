<script setup>
    import { reactive, ref, nextTick, onMounted } from 'vue';
    import axios from 'axios';
    import { useStore } from 'vuex'
    const store = useStore()
    import router from '@/router';


    const form = reactive({
        email: null,
        name: null,
        location: null,
        phone: null,
        })

    var isPurchaser = ref(false)

    if(store.getters.stateUser != null){
        if(store.getters.stateUser.role.user_role == "Purchaser" || store.getters.stateUser.role.user_role == "Client"){
            isPurchaser.value = true
        }
    }

    const updateForm = reactive({
        user_units_pressure: null,
        user_units_length: null,
        date_format: null,
        user_units_speed: null,
        user_roto_username: null,
        user_roto_password: null,
        current_pass: null,
        new_pass: null,
        reenter_pass: null,
        client_id: store.getters.stateUser != null ? store.getters.stateUser.associated_client : null,
        user_id: store.getters.stateUser != null ? store.getters.stateUser.id : null,
    })

    const show = ref(true)
    const onSubmit = async (event) => {
        event.preventDefault()
        console.log("form",form,updateForm)
        await axios.post('update_user_prefrence', updateForm)
        .then(response => (
                console.log("response in update_user_prefrence",response)
            )
        )
    }
    
    const onReset = (event) => {
        event.preventDefault()
        // Reset our form values
        form.email = ''
        form.name = ''
        // Trick to reset/clear native browser form validation state
        show.value = false
        nextTick(() => {
            show.value = true
        })
    }


    async function getUserProfileData(){
        var userId = store.getters.stateUser.id
        if(userId != null){

            await axios.post('get_user_profile_details', { "id": userId})
            .then(response => (
                console.log("response in getUserProfileData",response),
                    form.name = response.data.name,
                    form.email = response.data.email,
                    form.location = response.data.location,
                    form.phone = response.data.phone,
                    updateForm.date_format = response.data.date_format,
                    updateForm.user_units_length = response.data.user_units_length,
                    updateForm.user_units_pressure = response.data.user_units_pressure,
                    updateForm.user_units_speed = response.data.user_units_speed,
                    updateForm.user_roto_username =  atob(response.data.roto_login_username),
                    updateForm.user_roto_password =  atob(response.data.roto_login_password)
                )
            )

        }else{
            router.push({ name: 'Home'})
        }
    }
    
    onMounted(() => {
        getUserProfileData()
    })
    
</script>

<template>

<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" />
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        <div class="card settings-card">
            <div class="form-wrapper">
                
                <BForm @submit="onSubmit" @reset="onReset">
                <div class="container-fluid">
                    <div class="row form-row">
                        <h4>User Details</h4>
                        <div class="col-lg-6 indv-input">
                            <BFormGroup id="input-group-1" label="Name" label-for="name" >
                            <BFormInput disabled="true" id="name" v-model="form.name" type="text" size="sm"  />
                            </BFormGroup>
                        </div>
                        <div class="col-lg-6 indv-input">
                            <BFormGroup id="input-group-1" label="Email" label-for="email" >
                            <BFormInput disabled="true" id="email" v-model="form.email"  type="text" size="sm"  />
                            </BFormGroup>
                        </div>
                        
                    </div>
                    <div class="row form-row">
                        <div class="col-lg-6 indv-input">
                            <BFormGroup id="input-group-1" label="Location" label-for="input-1" >
                            <BFormInput disabled="true" v-model="form.location"  type="text" size="sm"  />
                            </BFormGroup>
                        </div>
                        <div class="col-lg-6 indv-input">
                            <BFormGroup id="input-group-1" label="Phone" label-for="input-1" >
                            <BFormInput disabled="true" v-model="form.phone"  type="text" size="sm"  />
                            </BFormGroup>
                        </div>
                    </div>
                    
                    <hr>

                    <div class="row form-row">
                        <h5>Unit Settings</h5>
                        <div class="col-lg-6 indv-input">
                            <BFormGroup id="input-group-1" label="Dates" label-for="input-1" >
                                <BFormRadioGroup id="radio-group-2" v-model="updateForm.date_format" name="radio-sub-component">
                                    <BFormRadio value="MM/DD/YYYY">MM/DD/YYYY</BFormRadio>
                                    <BFormRadio value="YYYY-MM-DD">YYYY-MM-DD</BFormRadio>
                                </BFormRadioGroup>
                            </BFormGroup>
                        </div>
                        <div class="col-lg-6 indv-input">
                            <BFormGroup id="input-group-1" label="Length" label-for="input-1" >
                                <select v-model="updateForm.user_units_length" class="form-select form-select-sm" aria-label="length-select">
                                    <option :selected = "updateForm.user_units_length == null" value='null'>Please Select</option>
                                    <option :selected = "updateForm.user_units_length == 'in'" value="in">Inches</option>
                                    <option :selected = "updateForm.user_units_length == 'ft'" value="ft">Feet</option>
                                    <option :selected = "updateForm.user_units_length == 'yds'" value="yds">Yards</option>
                                    <option :selected = "updateForm.user_units_length == 'mm'" value="mm">Millimeters</option>
                                    <option :selected = "updateForm.user_units_length == 'cm'" value="cm">Centimeters</option>
                                    <option :selected = "updateForm.user_units_length == 'm'" value="m">Meters</option>
                                    <option :selected = "updateForm.user_units_length == 'km'" value="km">Kilometers</option>
                                </select>
                            </BFormGroup>

                            
                        </div>
                    </div>
                    <div class="row form-row">
                        <div class="col-lg-6 indv-input">
                            <BFormGroup id="input-group-1" label="Pressure" label-for="input-1" >
                                <select v-model="updateForm.user_units_pressure" class="form-select form-select-sm" aria-label="length-select">
                                    <option :selected = "updateForm.user_units_pressure == null" value='null'>Please Select</option>
                                    <option :selected = "updateForm.user_units_pressure == 'psi'" value="psi">psi</option>
                                    <option :selected = "updateForm.user_units_pressure == 'kPa'" value="kPa">kPa</option>
                                    <option :selected = "updateForm.user_units_pressure == 'Bar'" value="Bar">Bar</option>
                                </select>
                            </BFormGroup>
                        </div>
                        <div class="col-lg-6 indv-input">
                            <BFormGroup id="input-group-1" label="Speed" label-for="input-1" >
                                <select v-model="updateForm.user_units_speed" class="form-select form-select-sm" aria-label="length-select">
                                    <option :selected = "updateForm.user_units_speed == null" value='null'>Please Select</option>
                                    <option :selected = "updateForm.user_units_speed == 'in/s'" value="in/s">Inch/Sec</option>
                                    <option :selected = "updateForm.user_units_speed == 'ft/s'" value="ft/s">Feet/Sec</option>
                                    <option :selected = "updateForm.user_units_speed == 'ft/min'" value="ft/min">Feet/Min</option>
                                    <option :selected = "updateForm.user_units_speed == 'mph'" value="mph">Miles/Hour</option>
                                    <option :selected = "updateForm.user_units_speed == 'mm/s'" value="mm/s">Millimeters/Sec</option>
                                    <option :selected = "updateForm.user_units_speed == 'cm/s'" value="cm/s">Centimeters/Sec</option>
                                    <option :selected = "updateForm.user_units_speed == 'm/min'" value="m/min">Meters/Min</option>
                                    <option :selected = "updateForm.user_units_speed == 'kph'" value="kph">killometers/Hour</option>
                                </select>
                            </BFormGroup>
                        </div>
                        
                    </div>
                    
                    <hr>

                    <div v-if="isPurchaser">
                        <div class="row form-row">
                            <h5>MyRoto Login</h5>
                            <div class="col-lg-6 indv-input">
                                <BFormGroup id="input-group-1" label="Username"  label-for="input-1" >
                                <BFormInput  id="input-1" v-model="updateForm.user_roto_username" size="sm"  type="text"/>
                                </BFormGroup>
                            </div>
                            <div class="col-lg-6 indv-input">
                                <BFormGroup id="input-group-1" label="Password" size="sm" label-for="input-1" >
                                <BFormInput  id="input-1" v-model="updateForm.user_roto_password" size="sm"  type="password"/>
                                </BFormGroup>
                            </div>
                            
                        </div>
                        
                        <hr>
                    </div>

                    <div class="row form-row">
                        <h5>Change Your Password</h5>
                        <div class="col-lg-4 indv-input">
                            <BFormGroup id="input-group-1" label="Current Password" label-for="input-1" >
                            <BFormInput  id="input-1" v-model="updateForm.current_pass" size="sm"  type="password"/>
                            </BFormGroup>
                        </div>
                        
                   
                        <div class="col-lg-4 indv-input">
                            <BFormGroup id="input-group-1" label="New Password" label-for="input-1" >
                            <BFormInput  id="input-1" v-model="updateForm.new_pass" size="sm" type="password"/>
                            </BFormGroup>
                        </div>
                        <div class="col-lg-4 indv-input">
                            <BFormGroup id="input-group-1" label="Re-Enter New Password" label-for="input-1" >
                            <BFormInput  id="input-1" v-model="updateForm.reenter_pass" size="sm" type="password"/>
                            </BFormGroup>
                        </div>
                    </div>
                    
                    <hr>

                    <div class="sumbit-btn-wrapper">
                        <BButton class="form-submit-btn" type="submit" variant="primary">Submit</BButton>
                    </div>
                    </div>
                    
                </BForm>
            </div>
            

        </div>
        
      </div>
    </BCol>
  </BRow>
  
</BContainer>
</template>

<script>
    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'


    export default {
        name: 'ManageProfileView',
        components: {
            SidebarComponent,
            NavbarComponent
            // HelloWorld
        }
    }
</script>