<script setup>

    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'
    import DataTableComponent from '../components/DataTableComponent.vue'
    import ModalComponent from '@/components/ModalComponent.vue';

    import { ref,onMounted } from 'vue'
    import $ from 'jquery'
    import axios from 'axios';
    import { useStore } from 'vuex'
    const store = useStore()
    import router from '@/router';


    var jobStatusData = ref([])
    var adjustmentData = ref([])

    const options = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": -1}},
        ordering: false,
        createdRow: function (row, data, index) {
          console.log("row",row,data,index)
          $(row).attr("id", data[0])
        
        },
    }

    const adjustmentOptions = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": -1}},
        ordering: false,
        // "drawCallback": function( settings ) {
        //       var api = this.api();
      
        //       // Output the data for the visible rows to the browser's console
        //       console.log( api.rows( {page:'current'} ).data() );
        //   },
          
          // "createdRow": function (row, data, index) {
          //   $('td:eq(1)', row).html( data[1]+ '<button class="btn edit-rev-count"><i class="fa-solid fa-pen"></i></button>' );
          // },
    }

    const adjColumns = [
      { render : function ( data ) {
          return '<span class="adj-side-highlight">'+ data +'</span>'
        }
      },
      { render : function ( data ) {
          return '<span class="adj-side-highlight">'+ data +'</span>'
        }
      },
      {},
      {},
      {},
      {},
    ]

    const columns = [
      { visible: false },
      {},
      {},
      {},
      {},
      {},
      {  render : function ( data ) {
        return data > 0 ? '<button class="btn data-table-btn data-table-adj-btn job-adjustments">'+ data +'</button>'  : '<button disabled class="btn data-table-btn data-table-adj-btn job-adjustments">'+ data +'</button>'
      } },
      {},
      { render : function ( data ) {
        return  '<button class="btn data-table-btn view-history" id="' + data + ' ">View History <i class="fa-solid fa-clock-rotate-left"></i></button>'
      } },
    ]

    // var jobStatusData= [
    // ['EUR34', '852500987783',  '11/28/2023 14:10:23',	'WHQ22-01-85694',	        'MOD22-01-45528',		           '0',    '0', '']	,
    // ['EUR34', 'TEST3.0701128', '11/28/2023 14:07:48',	'MAGCYL1128.,/-09P876YUIK',	'DIE1128000000/////////// ', '54',	 '1', '']	,
    // ['Max',	 'TESTEUKF',      '11/28/2023 10:47:03',	'MAG-987654321-987',	    'DIE-12345678-654322',		     '45',   '0', '']	,
    // ['Max',	 'TEST3.070',     '11/17/2023 09:28:18',	'WAL18-10-88505',	        'WAL18-10-88506',	             '96',   '0', '']	,
    // ['Max',	 'NOV16',	      '11/16/2023 16:26:01',	'NOV16	',	                'NOV16',	                     '78',   '0', '']	,
    // ['Max',	 'S',	          '11/16/2023 09:38:53',	'Mag_565345',	                        'WAL18-10-88505',              '11',   '0', ''],
    // ['Max',	 'NOV9',	      '11/16/2023 09:43:38',	'NOV9',	                    'NOV9',		                     '63',   '0', ''],
    // ]

    // const adjustmentData =[
    //     ['-8.0', "Drive",	'11/28/2023', '17:00:57',			'Flex',	'DIE1128000000///////////' ],
    //     ['-8.0', "Drive",	'11/28/2023', '17:00:57',			'Flex',	'DIE1128000000///////////' ],
    //     ['-8.0', "Operator",	'11/28/2023', '17:00:57',			'Flex',	'DIE1128000000///////////' ],
    //     ['-8.0', "Drive",	'11/28/2023', '17:00:57',			'Flex',	'DIE1128000000///////////' ],
    //     ['-8.0', "Operator",	'11/28/2023', '17:00:57',			'Flex',	'DIE1128000000///////////' ],
    //     ['-8.0', "Drive",	'11/28/2023', '17:00:57',			'Flex',	'DIE1128000000///////////' ],
    //   ]

      async function getJobStatusData() {

       
        if(store.getters.stateUser != null){
          axios.post('get_job_status', store.getters.stateUser)
          .then(response => (
          console.log("response",response),
          jobStatusData.value = response.data)
          )
        }else{
          router.push({ name: 'Home'})
        }
      }

      async function getJobAdjustmentsData(jobId) {
        // var output = ''
        
        var userData = store.getters.stateUser
        console.log("serial in job is",jobId,userData.id)
        if(userData != null){
          await axios.post('get_adjustments', { "user_id": userData.id, "adjustment_id": jobId, "adjustment_type": "job"})
          .then(response => (
              console.log("response in getAdjustmentsData of job status",response),
              adjustmentData.value = response.data
            )
          )
          // return output
        }
        else{
          router.push({ name: 'Home'})
        }
      }

      $(document).on('click', '.job-adjustments', async function(e){ 
        var jobId = $(this).parent().parent().attr("id")
        await getJobAdjustmentsData(jobId)
        $('#modal-center').addClass('modal fade show')
        $('#modal-center').css('display','block')
        $('#modal-center').css('z-index','1055')
        $('body').css('overflow','hidden')
        $('body').addClass('modal-open')
        $('.b-overlay-wrap').attr('aria-busy','true')
        $('.b-overlay-wrap').html('<div class="b-overlay position-fixed" style="inset: 0px; z-index: 10;"><div class="position-absolute bg-dark" style="inset: 0px; opacity: 0.85;"></div><div class="position-absolute" style="top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%);"><!----></div></div>')
        // $("#modal-center").modal('show');
        e.stopImmediatePropagation();
    });

      $(document).on('click', '.b-overlay, .btn-close, .modal-close', function(){ 
        $('body').css('overflow','')
        $('body').removeClass('modal-open')
        $('#modal-center').removeClass('show')
        $('#modal-center').css('display','none')
        $('#modal-center').css('z-index','1055')
        $('.b-overlay-wrap').removeAttr('aria-busy','true')
        $('.b-overlay-wrap').html('')
        // $("#modal-center").modal('show');
      });

      $(document).on('click', '.view-history', async function(e){ 
        
        var jobName = $(this).attr("id")
        console.log("here printed",jobName)
        router.push({ path: `/jobhistory/${jobName}` })
        e.stopImmediatePropagation();
    });

      onMounted(() => {
        getJobStatusData();
      })
</script>

<template>

<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" />
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        
        <DataTableComponent :data="jobStatusData" :columns="columns" :options="options">
            <template #table_header>
                <th>job_id</th>
                <th>Device Name</th>
                <th>Job Name</th>
                <th>Last Run</th>
                <th>Magid</th>
                <th>Dieid</th>
                <th>Adjustments Made</th>
                <th>Revolutions</th>
                <th>Action</th>
            </template>
        </DataTableComponent>


      </div>

      
    </BCol>
  </BRow>

  <ModalComponent modalTitle="Adjustment Info">
    <template #modal-body>
      <DataTableComponent :data="adjustmentData" :columns="adjColumns" :options="adjustmentOptions">
          <template #table_header>
            <th>Drive Side Adjustments	</th>
            <th>Operator Side Adjustments</th>
            <th>When Adjusted	</th>
            <th>Mag Cyl Serial	</th>
            <th>Die Serial</th>      
            <th>Die Type	</th>  
          </template>
      </DataTableComponent>
    </template>
    
  </ModalComponent>
  
</BContainer>
</template>
