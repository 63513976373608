import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import users from './modules/users';
// import * as Cookies from 'js-cookie'


export default createStore({
  plugins: [
    createPersistedState({
      // getState: (key) => Cookies.getJSON(key),
      // setState: (key, state) => Cookies.set(key, state, { expires: 1, secure: true }),
      // removeItem: (key) => Cookies.remove(key)
      key: 'maxcessfife',
      storage: window.localStorage  
    })
  ],
  modules: {
    users,
  }
});