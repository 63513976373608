<script setup>
//   import $ from 'jquery'
//   import { ref, onMounted } from 'vue'
  import { useStore } from 'vuex'
//   import router from '@/router';

  const store = useStore()

    async function logout() {
        await store.dispatch('logOut');
    }

</script>


<template>
    <router-view>
    <div id="mySidebar" class="sidebar shadow sidebar-expand">
        <div class="link-wrapper">
            <span><router-link to="/admindashboard" class="side-link"><font-awesome-icon :icon="['fas', 'users']" /> Manage Clients</router-link></span>
            <span><router-link to="/managestaffusers" class="side-link"><font-awesome-icon :icon="['fas', 'user-shield']" /> Manage Staff Users</router-link></span>
            <!-- <span><router-link to="" class="side-link"><font-awesome-icon :icon="['fas', 'laptop-code']" /> System Setup</router-link></span>
            <span><router-link to="" class="side-link"><font-awesome-icon :icon="['fas', 'bug']" /> Error Logs</router-link></span> -->
            <span><router-link @click="logout" to="/adminlogin" class="side-link"><font-awesome-icon :icon="['fas', 'right-from-bracket']" size="2xs" />&nbsp; Logout</router-link></span>
        </div>
    
      </div>
    </router-view>
</template>
