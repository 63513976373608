<script setup>
  import { ref } from 'vue'
  import NavbarComponent from '../components/NavbarComponent.vue'
  // import { mapActions } from 'vuex';
  import { useStore } from 'vuex'
  const store = useStore()
  const emailAddress = ref('')
  const passWord = ref('')
  import router from '@/router';

  // ...mapActions(['logIn']),
  async function submit() {
    const User = new FormData();
    User.append('username', emailAddress.value);
    User.append('password', passWord.value);
    await store.dispatch('logIn',User);
    router.push({ name: 'Dashboard' });
  }

  


</script>




<template>
  <NavbarComponent :sideBarEnable="false"/>
  <BContainer class="vh-100 d-flex justify-content-center align-items-center">
  <BRow>
    <BCol>
      
      <div class="login-container text-center">
        <form @submit.prevent="submit" action="/dashboard">
        <div class="login-container-header p-3">
          <h4 class="mt-2 ps-5 pe-5"><div>Welcome back,</div>
             <span class="">Please sign in to your account below.</span>  
          </h4>  
        </div>
        <div class="login-container-body p-3">
          <BFormInput class="form-inputs" name="username" type="text" required  v-model="emailAddress" placeholder="Email Address" />
          <BFormInput class="form-inputs mt-3" name="password" type="password" required  v-model="passWord" placeholder="Password" />
        </div>
        <div class="login-container-footer w-100 d-flex justify-content-end p-3">
          <button class="btn btn-lg btn-link recover-pass-btn">Recover Password</button>
          <button class="login-submit-btn btn">Submit</button>
        </div>
      </form>
      </div>
      <div  class="text-center text-black opacity-50 mt-3">Copyright © Maxcess International Inc. 2023</div>
    </BCol>
  </BRow>
  </BContainer>
</template>

<script>
  

  // function login(){
  //   if(emailAddress.value && passWord.value){
  //     emailAddress.value = ''
  //     passWord.value = ''
  //     router.push({ name: 'Dashboard' })
      
  //   }
    
  
  // }
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'
    // import MainComponent from '../components/MainComponent.vue'
    export default {
      name: 'HomeView',
      components: {
        // MainComponent
        // HelloWorld
      },
      methods: {
        
      }
    }
</script>