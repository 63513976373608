<template>
    
  
    <BModal ref="adjustmentModal" ok-only="true" ok-title="CLose" ok-variant="danger modal-close" id="modal-center" size="xl" :modelValue="modalVisiblity"  centered :title = "modalTitle">
      <slot name="modal-body" >
      </slot>
    </BModal>
</template>
  
  <script setup>
//   import ref from 'vue'
//   const modal = ref(false)
  </script>

<script>
export default {
    name: 'ModalComponent',
    components: {

    },
    props: ['modalTitle','modalVisiblity']
}
</script>