<script setup>
    import NavbarComponent from '@/components/NavbarComponent.vue'
    import AdminSidebarComponent from '@/components/AdminSidebarComponent.vue'
    import DataTableComponent from '@/components/DataTableComponent.vue'
    import $ from 'jquery'

    import { ref, onMounted, reactive } from 'vue'
    import axios from 'axios';
    import { useStore } from 'vuex'
    const store = useStore()
    import router from '@/router';

    var staffTableData = ref([])
    var showStaffForm = ref(false)
    var rolesData = ref([])
    var editform = ref(false)
    var changePass = ref(false)
    var showStaffViewDetails = ref(false)

    const options = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": 0}},
        ordering: false,
        createdRow: function (row, data) {
          $(row).attr("id", data["id"])
        },
    }

    

    var newStaffUserForm = reactive({
      id: null,
      email: null,
      username: null,
      first_name: null,
      last_name: null,
      staff_role: null,
      mobile_number: null,
      password: null,
      reEnteredPassword: null,
      is_active: null,
      user_type: null,
    })


    var columns = [
      { visible: false, data: "id" },
      { data: "username" },
      { data: "email" },
      { data: "phone" },
      {
        data: "status",
        render : function ( data ) {
          if(data==true){
            return '<span class="user-active">Active</span>'
          }else{
            return '<span class="user-inactive">Inactive</span>'
          }
          
        }
      },
      {
        render : function (  ) {
          return '<button class="btn view-btn admin-action-btn staff-view-data"><i class="fa-solid fa-eye" style="color: #f2f2f2;"></i></i></button> <button  class="btn btn-primary edit-btn admin-action-btn staff-edit-data"><i class="fa-solid fa-pen-to-square" style="color: #ffffff;"></i></button>  <button class="btn btn-danger admin-action-btn delete-staff-user"><i class="fa-solid fa-trash" style="color: #ffffff;"></i></button>'
        }
      },
    ]

    function resetForm(){
      newStaffUserForm.id = null
      newStaffUserForm.username = null
      newStaffUserForm.email = null
      newStaffUserForm.mobile_number = null
      newStaffUserForm.first_name = null
      newStaffUserForm.password = null
      newStaffUserForm.reEnteredPassword = null
      newStaffUserForm.is_active = null
      newStaffUserForm.last_name = null
      newStaffUserForm.staff_role = null
      newStaffUserForm.user_type = null
      if(editform.value){
        editform.value = !editform.value
      }
    }

   

    async function getStaffUserData() {

      // var clientId = store.getters.stateUser.associated_client
      if(store.getters.stateUser != null){
        axios.get('get_staff_users')
          .then(response => (
            console.log("response",response),
            staffTableData.value = response.data.staff_table_data,
            rolesData.value = response.data.staff_roles_data
        )
        )
      }else{
        router.push({ name: 'AdminLogin'})
      }
    }


    function onNewClientInput(phone, phoneObject) {
        console.log("phone",phone,phoneObject)
        if (phoneObject?.formatted) {
          newStaffUserForm.mobile_number = phoneObject.number
        }
      }


    const onSubmitClient = async (event) => {
        event.preventDefault()
        console.log("newform",newStaffUserForm)

        if(editform.value == true){
          console.log("in here",newStaffUserForm)
          await axios.post('edit_staff_users_details', newStaffUserForm)
            .then(response => (
              console.log("response in edit_client_data",response)
            )
          )
        }else{
          await axios.post('create_staff_user', newStaffUserForm)
            .then(response => (
              console.log("response in create_staff_user",response) 
            )
          )
        }
        

        await getStaffUserData()
        back()

        // alert(JSON.stringify(form))
    }

    function back(){
      showStaffForm.value = !showStaffForm.value;
      resetForm();

      if(editform.value){
        editform.value=!editform.value
      }
      
      if(changePass.value){
        changePass.value = !changePass.value
      }
    }
    


    $(document).on('click', '.staff-edit-data', async function(){ 
      var editId = $(this).parent().parent().attr("id")

      console.log("editId",editId)
    
      editform.value = !editform.value
      changePass.value = !changePass.value
      await axios.post('get_staff_users_details', {"id": editId})
        .then(response => (
          newStaffUserForm.id = editId,
          newStaffUserForm.email = response.data.email,
          newStaffUserForm.username = response.data.username,
          newStaffUserForm.first_name = response.data.first_name,
          newStaffUserForm.last_name = response.data.last_name,
          newStaffUserForm.staff_role = response.data.staff_role,
          newStaffUserForm.mobile_number = response.data.mobile_number,
          newStaffUserForm.is_active = response.data.is_active
            
        )
      )
      showStaffForm.value = !showStaffForm.value
      
    });

    $(document).on('click', '.staff-view-data', async function(){ 
      var viewId = $(this).parent().parent().attr("id")
    
      showStaffViewDetails.value = !showStaffViewDetails.value
      
      await axios.post('get_staff_users_details', {"id": viewId})
        .then(response => (
          newStaffUserForm.id = viewId,
          newStaffUserForm.email = response.data.email,
          newStaffUserForm.username = response.data.username,
          newStaffUserForm.first_name = response.data.first_name,
          newStaffUserForm.last_name = response.data.last_name,
          newStaffUserForm.staff_role = response.data.staff_role,
          newStaffUserForm.mobile_number = response.data.mobile_number,
          newStaffUserForm.is_active = response.data.is_active,
          newStaffUserForm.user_type = response.data.role_name
        )
      )
      
    });

    onMounted(async () => {
      await getStaffUserData();
    })


</script>

<template>

<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" :isAdmin="true"/>
  </BRow>
  <BRow>
    <BCol>
      <AdminSidebarComponent />

      <div class="main-expand" id="main">
        
        <DataTableComponent :data="staffTableData" :columns="columns" :options="options" v-if="!showStaffForm && !showStaffViewDetails">
          <template #card_heading>
              <div class="row">
                <div class="col-sm-6">
                  <h3 class="card-head-title">Manage Staff Users</h3>
                </div>
                
                <div class="col-sm-6">
                  <div class="d-flex justify-content-end">
                    <BButton variant="primary" @click="showStaffForm = !showStaffForm" class="md add-user-btn" ><font-awesome-icon :icon="['fas', 'user-plus']" /> &nbsp;Add New Staff User</BButton>
                  </div>
                </div>
              </div>
                
                
            </template>
            <template #table_header>
                <th>id</th>
                <th>Username</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Status</th>
                <th>Actions</th>
            </template>
        </DataTableComponent>


        <div class="card settings-card"  v-if="showStaffForm">
            <div class="form-wrapper">
              <BForm @submit="onSubmitClient" @reset="resetForm">
                <div class="row">
                  <div class="col-sm-6">
                    <h3 v-if="editform" class="card-head-title">Edit Staff User</h3>
                    <h3 v-else class="card-head-title">Add New Staff User</h3>
                  </div>
                  
                  <div class="col-sm-6">
                    <div class="d-flex justify-content-end">
                      <BButton variant="primary" class="md"  @click="back()">Go Back</BButton>
                    </div>
                  </div>
                </div>

                <div class="row form-row">              

                      <div class="col-lg-6 indv-input">
                          <BFormGroup id="input-group-1" label="Email Address" label-for="Email Address" >
                            <BFormInput v-model="newStaffUserForm.email" :disabled="editform" placeholder="Enter your email"  type="email" size="md"  required />
                          </BFormGroup>
                          
                      </div>

                      <div class="col-lg-6 indv-input">
                          <BFormGroup id="input-group-1" label="Phone" label-for="name" >
                              <vue-tel-input class="form-control form-control-sm" :value="newStaffUserForm.mobile_number" @input="onNewClientInput" mode="international" :required="true"></vue-tel-input>
                          </BFormGroup>
                          
                      </div>
                      
                      
                </div>

                <div class="row form-row">              

                  <div class="col-lg-6 indv-input">
                      <BFormGroup id="input-group-1" label="Username" label-for="Username" >
                          <BFormInput v-model="newStaffUserForm.username" placeholder="Enter your Username"   type="text" size="md"  required />
                      </BFormGroup>


                  </div>

                  <div class="col-lg-6 indv-input">
                    <BFormGroup id="input-group-1" label="Role" label-for="email" >
                        <select v-model="newStaffUserForm.staff_role" class="form-select form-select-md" aria-label="length-select" required>
                            <option value="">Please Select</option>
                            <option v-for="role in rolesData" :selected="newStaffUserForm.staff_role == role.id"  :key="role.id" :value="role.id">{{ role.user_role }}</option>
                        </select>
                    </BFormGroup>
                      
                  </div>

                  

                </div>


                  <div class="row form-row" >              

                      <div class="col-lg-6 indv-input" v-if="!changePass">
                          <BFormGroup id="input-group-1" label="Password" label-for="name" >
                          <BFormInput v-model="newStaffUserForm.password" placeholder="Enter password"   type="password" size="md"  required />
                          </BFormGroup>
                          
                      </div>
                      <div class="col-lg-6 indv-input" v-if="!changePass">
                          <BFormGroup id="input-group-1" label="Re-enter Password" label-for="" >
                          <BFormInput v-model="newStaffUserForm.reEnteredPassword" placeholder="Re-enter password"  type="password" size="md" required />
                          </BFormGroup>
                      </div>

                      <div class="d-flex justify-content-end" v-if="changePass"><BButton class="btn form-btn" @click="changePass = !changePass">Change Password</BButton></div>
                  </div>


                  <div class="row form-row">
                    <div class="col-lg-6 indv-input">
                        <BFormGroup id="input-group-1" label="First Name" label-for="" >
                        <BFormInput v-model="newStaffUserForm.first_name" placeholder="Enter your First Name"  type="text" size="md" required />
                        </BFormGroup>
                    </div>

                    <div class="col-lg-6 indv-input">
                      <BFormGroup id="input-group-status" label="Last Name" label-for="" >
                        <BFormInput v-model="newStaffUserForm.last_name" placeholder="Enter your Last Name"  type="text" size="md" required />
                      </BFormGroup>
                    </div>
                  </div>


                <div class="row form-row">

                  <div class="col-lg-6 indv-input">
                    <BFormGroup id="input-group-status" label="Status:" label-for="" >
                      <BFormRadioGroup id="radio-group-status" v-model="newStaffUserForm.is_active" name="radio-staff-sub-component" :required="true">
                        <BFormRadio :value=true>Active</BFormRadio>
                        <BFormRadio :value=false>Inactive</BFormRadio>
                      </BFormRadioGroup>
                    </BFormGroup>
                  </div>
                </div>


                <hr>

                <div class="row">
                  <div v-if="editform" class="d-flex justify-content-end"> <BButton class="form-submit-btn" type="submit" variant="primary">Update Staff User</BButton></div>
                  <div v-else class="d-flex justify-content-end"> <BButton class="form-submit-btn" type="submit" variant="primary">Add Staff User</BButton></div>
                </div>

              </BForm>
            </div>
        </div>


        <div class="card settings-card"  v-if="showStaffViewDetails">
          <div class="form-wrapper">
            <div class="row">
                <div class="col-sm-6">
                  <h3 class="card-head-title">Staff User Profile Information</h3>
                </div>
                
                <div class="col-sm-6">
                  <div class="d-flex justify-content-end">
                    <BButton variant="primary" class="md"  @click="showStaffViewDetails = !showStaffViewDetails;resetForm()">Go Back</BButton>
                  </div>
                </div>
            </div>

            <div class="row form-row">
              <div class="col-sm-6">First Name:: {{ newStaffUserForm.first_name }}</div>
              <div class="col-sm-6">Last Name: {{ newStaffUserForm.last_name }}</div>
            </div>
            
            <div class="row form-row">
              <div class="col-sm-6">Username: {{ newStaffUserForm.username }}</div>
              <div class="col-sm-6">Email Address: {{ newStaffUserForm.email }}</div>
            </div> 

            <div class="row form-row">
              <div class="col-sm-6">Mobile Number: {{ newStaffUserForm.mobile_number }}</div>
              <div class="col-sm-6">User Type: {{ newStaffUserForm.user_type }}</div>
            </div> 

            <div class="row form-row">
              <div v-if="newStaffUserForm.is_active" class="col-sm-6">Status: Active</div>
              <div v-else class="col-sm-6">Status: Inactive</div>
            </div> 

          </div>
        </div>


      </div>

      
    </BCol>
  </BRow>
  
</BContainer>
</template>
