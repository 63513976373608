<script setup>
    import { ref } from 'vue'
    import $ from 'jquery'

    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        $('#barcode').val(result) ;
    }


    const options = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": -1}},
        ordering: true,
    }

    var operatorData =[
        ["Abhishek", "fsd6sdg1s61g", "Active"]
    ]

    var columns = [
      {},
      {},
      {},
      {  render : function ( data ) {
        return '<button class="btn view-btn delete-btn"><i class="fa-solid fa-eye" style="color: #f2f2f2;"></i></i></button> <button class="btn btn-primary edit-btn delete-btn"><i class="fa-solid fa-pen-to-square" style="color: #ffffff;"></i></button>  <button class="btn btn-danger delete-btn"><i class="fa-solid fa-trash" style="color: #ffffff;"></i></button>'
      }},
    ]
    

      var showForm = ref(false)

      var showViewDetails = ref(false)

      var showEditDetails = ref(false)

      var changePassword = ref(false)

      $(document).on('click', '.view-btn', function(){ 
        showViewDetails.value = ! showViewDetails.value
      });

      $(document).on('click', '.edit-btn', function(){ 
        changePassword.value = false
        showEditDetails.value = ! showEditDetails.value
      });
</script>

<template>

<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" />
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        
        <DataTableComponent :data="operatorData"  :columns="columns" :options="options" v-if="!showForm && !showViewDetails && !showEditDetails">
            <template #card_heading>
              <div class="row">
                <div class="col-sm-6">
                  <h3 class="card-head-title">Manage Operators</h3>
                </div>
                
                <div class="col-sm-6">
                  <div class="d-flex justify-content-end">
                    <BButton variant="primary" class="md add-operator-btn" @click="showForm = !showForm"><font-awesome-icon :icon="['fas', 'plus']" /> &nbsp; Add New Operator</BButton>
                  </div>
                </div>
              </div>
                
                
            </template>
            <template #table_header>
                <th>Name</th>
                <th>Barcode Id</th>
                <th>Status</th>
                <th>Action</th>
                
            </template>
        </DataTableComponent>

        <div class="card settings-card"  v-if="showForm">
            <div class="form-wrapper">
              <div class="row">
                <div class="col-sm-6">
                  <h3 class="card-head-title">Add New Operator</h3>
                </div>
                
                <div class="col-sm-6">
                  <div class="d-flex justify-content-end">
                    <BButton variant="primary" class="md"  @click="showForm = !showForm">Go Back</BButton>
                  </div>
                </div>
              </div>

              <div class="row form-row">              

                    <div class="col-lg-6 indv-input">
                        <BFormGroup id="input-group-1" label="Operator Name" label-for="name" >
                          <BFormInput type="text" size="md"  required />
                        </BFormGroup>
                        
                    </div>
                    <div class="col-lg-6 indv-input">
                        <BFormGroup id="input-group-1" label="Barcode ID" label-for="email" >
                          <div class="row">
                              <div class="col-sm-8"><BFormInput id="barcode"  type="text" size="md"  required /></div>
                              <div class="col-sm-4"> <BButton @click="makeid(10)" class="btn form-btn" >Generate</BButton></div>
                          </div>
                        </BFormGroup>


                    </div>
                    
              </div>

              <div class="row form-row">              

                <div class="col-lg-6 indv-input">
                    <BFormGroup id="input-group-1" label="Password" label-for="name" >
                      <BFormInput   type="text" size="md"  required />
                    </BFormGroup>
                    
                </div>
                <div class="col-lg-6 indv-input">
                    <BFormGroup id="input-group-1" label="Re-enter Password" label-for="email" >
                      <BFormInput  type="text" size="md"  required />
                    </BFormGroup>
                </div>

              </div>

              <div class="row form-row">
                <BFormGroup id="input-group-1" label="Status:" label-for="email" >
                  <BFormRadioGroup id="radio-group-2" v-model="groupedSelected" name="radio-sub-component">
                    <BFormRadio value="first">Active</BFormRadio>
                    <BFormRadio value="second">Inactive</BFormRadio>
                  </BFormRadioGroup>
                </BFormGroup>
              </div>
              <div class="row">
                <div class="d-flex justify-content-end"> <BButton class="form-submit-btn" type="submit" variant="primary">Add Operator</BButton></div>
              </div>

            </div>
        </div>



        <div class="card settings-card"  v-if="showEditDetails">
            <div class="form-wrapper">
              <div class="row">
                <div class="col-sm-6">
                  <h3 class="card-head-title">Edit Operator</h3>
                </div>
                
                <div class="col-sm-6">
                  <div class="d-flex justify-content-end">
                    <BButton variant="primary" class="md"  @click="showEditDetails = !showEditDetails">Go Back</BButton>
                  </div>
                </div>
              </div>

              <div class="row form-row">              

                <div class="col-lg-6 indv-input">
                    <BFormGroup id="input-group-1" label="Operator Name" label-for="name" >
                      <BFormInput   type="text" size="md"  required placeholder="Abhishek" />
                    </BFormGroup>
                    
                </div>
                <div class="col-lg-6 indv-input">
                    <BFormGroup id="input-group-1" label="Barcode ID" label-for="email" >
                      <div class="row">
                          <div class="col-sm-8"><BFormInput  type="text" size="md" placeholder="u5NqNLxKcq"  required /></div>
                          <div class="col-sm-4"> <BButton class="btn form-btn" >Generate</BButton></div>
                      </div>
                    </BFormGroup>


                </div>

              </div>


                <div class="row form-row">              

                    <div class="col-lg-6 indv-input" v-if="changePassword">
                        <BFormGroup id="input-group-1" label="Password" label-for="name" >
                        <BFormInput   type="text" size="md"  required />
                        </BFormGroup>
                    </div>
                    <div class="col-lg-6 indv-input" v-if="changePassword">
                        <BFormGroup id="input-group-1" label="Re-enter Password" label-for="email" >
                        <BFormInput  type="text" size="md"  required />
                        </BFormGroup>
                    </div>

                    <div class="d-flex justify-content-end" v-if="!changePassword"><BButton class="btn form-btn" @click="changePassword = !changePassword">Change Password</BButton></div>
                </div>

              <div class="row form-row">
                <BFormGroup id="input-group-1" label="Status:" label-for="email" >
                  <BFormRadioGroup id="radio-group-2" v-model="groupedSelected" name="radio-sub-component">
                    <BFormRadio value="first">Active</BFormRadio>
                    <BFormRadio value="second">Inactive</BFormRadio>
                  </BFormRadioGroup>
                </BFormGroup>
              </div>

              <div class="row">
                <div class="d-flex justify-content-end"> <BButton class="form-submit-btn" type="submit" variant="primary">Update Operator</BButton></div>
              </div>

            </div>
        </div>



        <div class="card settings-card"  v-if="showViewDetails">
          <div class="form-wrapper">
            <div class="row">
                <div class="col-sm-6">
                  <h3 class="card-head-title">Operator Profile Information</h3>
                </div>
                
                <div class="col-sm-6">
                  <div class="d-flex justify-content-end">
                    <BButton variant="primary" class="md"  @click="showViewDetails = !showViewDetails">Go Back</BButton>
                  </div>
                </div>
            </div>

            <div class="row form-row">
              <div class="col-sm-6">Name: Abhishek</div>
              <div class="col-sm-6">Barcode ID: u5NqNLxKcq</div>
            </div> 

            <div class="row form-row">
              <div class="col-sm-6">Status: Active</div>
              
            </div> 

          </div>
        </div>



      </div>

      
    </BCol>
  </BRow>
  
</BContainer>
</template>

<script>
    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'
    import DataTableComponent from '../components/DataTableComponent.vue'



    export default {
        name: 'ManageOperatorsView',
        components: {
            DataTableComponent,
            SidebarComponent,
            NavbarComponent
            // HelloWorld
        }
    }
</script>