<script setup>
    import $ from 'jquery'
    import { ref, onMounted } from 'vue'
    import axios from 'axios';
    import { useStore } from 'vuex'
    import router from '@/router';

    const store = useStore()

    var maintenanceDataBackup = ref([])
    var upcomingMaintenanceData = ref([])
    var completedData = ref([])
    var filteredData = ref([])

    const options = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": -1}},
        ordering: false,
        createdRow: function (row, data, index) {
          console.log("row",row,data,index)
          $(row).attr("id", data[0])
          if(data[6] == 1){
            $(row).addClass("labelwarning-red");
          }else if ( data[6] == 2){
            $(row).addClass("labelwarning-orange");
          }else if ( data[6] == 3){
            $(row).addClass("labelwarning-green");
          }
        
        },
        // drawCallback: function() {
        //   console.log('inside drawcallback')
        //   var api = this.api();
 
        //   // Output the data for the visible rows to the browser's console
        //   console.log(api.rows({ page: 'current' }).data());
        // }
        // columnDefs: [ {
        // targets: -1,
        // createdCell: function (td, cellData, rowData, row, col) {
        //   console.log(cellData)
        //   if ( cellData == 1 ) {
        //     console.log("here")
        //     $(row).addClass("labelwarning");
        //   }
        //   else {
        //     return cellData
        //   }
        // },}]
        // scrollX: true
        // processing: true,
        // serverSide: true,
        // destroy: true,
    }

    const optionsCompleted = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": -1}},
        ordering: true,
      
    }

    const columns = [
      {visible: false},
      {},
      {},
      {},
      {},
      { render : function ( data ) {
        if(data == ""){
          return '<button type="button" class="btn complete-btn"><i class="fa-regular fa-circle-check fa-lg"></i> &nbsp; Complete</button>'
        }else{
          return '<button type="button" class="btn complete-btn"><i class="fa-regular fa-circle-check fa-lg"></i> &nbsp; Complete</button> <button type="button" id="' + data + '" class="btn btn-primary buy-now-btn"><i class="fa-solid fa-cart-plus fa-lg"></i> &nbsp; Buy Now</button>'
        }
        
      } },
      { visible:false,
        
      }
    ]

    // const upcomingMaintenanceData=[
    //     ['Max',	'Anvil Retool', '709 days remaining', 'Send to get retooled.', '', '', '1'],
    //     ['Max',	'Anvil Retool', '709 days remaining', 'Send to get retooled.', '', '', '1'],
    //     ['Max',	'Anvil Retool', '709 days remaining', 'Send to get retooled.', '', '', '1'],
    //     ['Max',	'Anvil Retool', '709 days remaining', 'Send to get retooled.', '', '', '2'],
    //     ['Max',	'Anvil Retool', '709 days remaining', 'Send to get retooled.', '', '', '2'],
    //     ['Max',	'Anvil Retool', '709 days remaining', 'Send to get retooled.', '', '', '2'],
    //     ['Max',	'Anvil Retool', '709 days remaining', 'Send to get retooled.', '', '', '3'],
    //     ['Max',	'Anvil Retool', '709 days remaining', 'Send to get retooled.', '', '', '3'],
        
    // ]

    const compColumns = [
    {},
    {},
    {},
    {},
    {},
    {}
    ]

    // const completedData=[
    //     ['Flex Die - iotDevice1 2 Test 0.125mm',	'Inspect Die', '101200 Revs', 'Schedule Inspection of the Flex Die.', '10/17/2023', 'DevOut'],
    //     ['Flex Die - iotDevice2 2 Test 0.125mm',	'Inspect Die', '355000 Revs', 'Schedule Inspection of the Flex Die.', '12/12/23', 'SONOCO'],
    //     ['Flex Die - iotDevice1 2 Test 0.125mm',	'Inspect Die', '101200 Revs', 'Schedule Inspection of the Flex Die.', '10/17/2023', 'DevOut'],
    //     ['Flex Die - iotDevice2 2 Test 0.125mm',	'Inspect Die', '355000 Revs', 'Schedule Inspection of the Flex Die.', '12/12/23', 'SONOCO'],
    //     ['Flex Die - iotDevice1 2 Test 0.125mm',	'Inspect Die', '101200 Revs', 'Schedule Inspection of the Flex Die.', '10/17/2023', 'DevOut'],
    //     ['Flex Die - iotDevice2 2 Test 0.125mm',	'Inspect Die', '355000 Revs', 'Schedule Inspection of the Flex Die.', '12/12/23', 'SONOCO'],
    // ]

    $(document).ready( function(){
      $('.dataTables_length').parent().first().removeClass('col-md-6').addClass('col-md-4').after('<div id="position" class="text-center col-sm-12 col-md-4 tab-label "><div class="align-middle form-check-inline"><label class="form-check-label"><input class="form-check-input table-filter" type="checkbox" name="filter" value="Others" id="Otherchk"><span>Others</span></label></div><div class="align-middle form-check-inline"><label class="form-check-label"><input class="form-check-input table-filter" type="checkbox" name="filter" value="Flex Die" id="flexDiechk"><span>Flex Die</span></label></div><div class="align-middle form-check-inline"><label class="form-check-label"><input class="form-check-input table-filter" type="checkbox" name="filter" value="Mag Cyl" id="MagCylchk"><span>Mag Cyl.</span></label></div>');
      $('.dataTables_filter').parent().first().removeClass('col-md-6').addClass('col-md-4');
    })

    $(document).on('click', '.complete-btn', async function(e){ 

      
      console.log("this one",$(this))
        console.log("here",$(this).parent().parent().attr("id"))
        var rmt_id = $(this).parent().parent().attr("id")
        await CompleteMaintenance(rmt_id)
        await getRequiredMaintenanceData()
        $("#completed_maintenance").click()

        

        // console.log("count",upcomingMaintenanceData.value.length,upcomingMaintenanceData.value)

        // for(var i=0; i < upcomingMaintenanceData.value.length; i++){
        //   if(upcomingMaintenanceData.value[i][0] == rmt_id){
        //     upcomingMaintenanceData.value = upcomingMaintenanceData.value.splice(i,1)
        //     console.log("this",upcomingMaintenanceData.value)
        //   }
        // }

        e.stopImmediatePropagation();
      });


      $(document).on('click', '.buy-now-btn', async function(e){ 

        console.log("here in buy now",$(this).attr("id"))
        var serial_number = $(this).attr("id")
        router.push({ path: "/buymyroto/"+ serial_number})
          

        e.stopImmediatePropagation();
      });

      
      

      $(document).on('click', '.table-filter', function(e){ 
        
        var filterArray = []
        filteredData.value = []

        $("input:checkbox[name=filter]:checked").each(function(){
          filterArray.push($(this).val());
        });

        console.log("filererarr",filterArray)

        for(var i=0;i<maintenanceDataBackup.value.length;i++){
          if(filterArray.includes(maintenanceDataBackup.value[i][7])){
            filteredData.value.push(maintenanceDataBackup.value[i])
          }
        }

        console.log("filteredData",filteredData)

        if(filterArray.length){
          console.log("here")
          upcomingMaintenanceData.value = filteredData.value
        }
        else{
          console.log("in else")
          upcomingMaintenanceData.value = maintenanceDataBackup.value
        }
        

        // e.stopImmediatePropagation();
      });

    async function getRequiredMaintenanceData() {

      var clientId = store.getters.stateUser.associated_client
      if(clientId != null){

        await axios.post('get_maintenance_data', { "id": clientId})
          .then(response => (
              console.log("response in req mianenance",response),
              upcomingMaintenanceData.value = response.data,
              maintenanceDataBackup.value = response.data
            )
            )

      }else{
        router.push({ name: 'Home'})
      }
    }

    async function getCompletedMaintenanceData() {

      var clientId = store.getters.stateUser.associated_client
      if(store.getters.stateUser != null){

        await axios.post('get_completed_maintenance', store.getters.stateUser)
        .then(response => (
            console.log("response in completed mianenance",response),
            completedData.value = response.data
          )
          )

      }else{
        router.push({ name: 'Home'})
      }
    }


      async function CompleteMaintenance(mt_id) {

        var userId = store.getters.stateUser.id
        var clientId = store.getters.stateUser.associated_client
        if(userId != null && clientId != null ){

          await axios.post('complete_maintenance', { "user_id": userId, "mt_id": mt_id, 'client_id': clientId})
            .then(response => (
                console.log("response in completed mianenance",response),
                completedData.value.push(response.data)
              )
            )
          
        }else{
          router.push({ name: 'Home'})
        }

        }

    onMounted(() => {
      getRequiredMaintenanceData();
      getCompletedMaintenanceData()
    })

    
</script>

<template>

<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" />
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        <BTabs>
            <BTab titleLinkClass="DS_tabs">
              <template #title>
                <span>Upcoming</span>
              </template>
                <DataTableComponent id="maintenancetable" :data="upcomingMaintenanceData" :columns="columns" :options="options">
                    <template #table_header>
                    <th>rm_id</th>
                    <th>Device</th>
                    <th>Maintenance Type</th>
                    <th>Current Interval</th>
                    <th>Description</th>
                    <th>Action</th>
                    </template>
                </DataTableComponent>
            </BTab>
            <BTab titleLinkClass="DS_tabs">
              <template #title>
                <span id="completed_maintenance">Completed</span>
              </template>
                <DataTableStrippedComponent :data="completedData" :columns="compColumns" :options="optionsCompleted">
                    <template #table_header>
                    <th>Device</th>
                    <th>Maintenance Type</th>
                    <th>Serviced At</th>
                    <th>Maintenance Description</th>
                    <th>Serviced On</th>
                    <th>Serviced By</th>
                    </template>
                </DataTableStrippedComponent>
            </BTab>
        </BTabs>
        
      </div>

      
    </BCol>
  </BRow>
</BContainer>
</template>

<script>
    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'
    import DataTableComponent from '../components/DataTableComponent.vue'
    import DataTableStrippedComponent from '../components/DataTableStripped.vue'


    export default {
        name: 'MaintenanceView',
        components: {
            DataTableComponent,
            SidebarComponent,
            NavbarComponent,
            // HelloWorld
        }
    }
</script>