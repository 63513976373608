<script setup>
    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'
    import DataTableComponent from '../components/DataTableComponent.vue'

    import { ref, onMounted, reactive } from 'vue';
    import axios from 'axios';
    import { useStore } from 'vuex'
    const store = useStore()
    import router from '@/router';
    import $ from 'jquery'

    const options = {
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
        responsive: {"details": {"type": 'column',"target": -1}},
        ordering: true,
    }

    var responseData = reactive({})

    var editform = reactive({})

    var viewForm = reactive({})

    var showForm = ref(false)

    var showViewDetails = ref(false)

    var showEditDetails = ref(false)

    var changePassword = ref(false)

    var newUserForm = reactive({
      username: null,
      email: null,
      mobile_number: null,
      location: null,
      associated_role: null,
      password: null,
      reEnteredPassword: null,
      is_active: null,
      associated_client: store.getters.stateUser != null ? store.getters.stateUser.associated_client : null,
    })

    
    // var userData =[
    //     ["Abhishek", "apote@maxcessintl.com", "+91 1234567890", "Purchaser", "Active"],
    //     ["Abhishek", "apote@maxcessintl.com", "+91 1234567890", "Purchaser", "Active"],
    //     ["Abhishek", "apote@maxcessintl.com", "+91 1234567890", "Purchaser", "Active"],
    //     ["Abhishek", "apote@maxcessintl.com", "+91 1234567890", "Purchaser", "Active"],
    //     ["Abhishek", "apote@maxcessintl.com", "+91 1234567890", "Purchaser", "Active"],
    // ]

    var userData = ref([])

    var columns = [
      {},
      {},
      {},
      {},
      {},
      {  render : function ( data ) {
        console.log("data in render",data)
        return '<button id="' + data +'" class="btn view-btn delete-btn"><i class="fa-solid fa-eye" style="color: #f2f2f2;"></i></i></button> <button id="' + data +'" class="btn btn-primary edit-btn delete-btn"><i class="fa-solid fa-pen-to-square" style="color: #ffffff;"></i></button>  <button id="' + data +'" class="btn btn-danger delete-btn"><i class="fa-solid fa-trash" style="color: #ffffff;"></i></button>'
      }},
    ]

    async function getUserData(){
        var clientId = store.getters.stateUser.associated_client
        if(clientId != null){
            await axios.post('get_client_users', { "client_id": clientId})
            .then(response => (
                console.log("response in get_client_users",response),
                userData.value = response.data.user_table_data,
                responseData = response.data
            )
            )
        }
        else{
            router.push({ name: 'Home'})
        }
    }


    const onUpdateUser = async (event) => {
        event.preventDefault()
        console.log("editform",editform)
        await axios.post('update_user_data', editform)
        .then(response => (
            console.log("response in onUpdateUser",response),
            getUserData(),
            showEditDetails.value = !showEditDetails.value
          )
        )
        // alert(JSON.stringify(form))
    }

    const onSubmitUser = async (event) => {
        event.preventDefault()
        console.log("newform",newUserForm)

        await axios.post('create_client_user', newUserForm)
        .then(response => (
            console.log("response in onUpdateUser",response),
            userData.value.unshift(response.data),

            newUserForm.username = null,
            newUserForm.email = null,
            newUserForm.mobile_number = null,
            newUserForm.location = null,
            newUserForm.associated_role = null,
            newUserForm.password = null,
            newUserForm.newPassword = null,
            newUserForm.is_active = null,

            showForm.value = !showForm.value
          )
        )

        

        // alert(JSON.stringify(form))
    }

      

      $(document).on('click', '.view-btn', function(){ 

        showViewDetails.value = ! showViewDetails.value
        var viewId = $(this).attr("id")
        viewForm = responseData[viewId]
      });

      $(document).on('click', '.edit-btn', function(){ 
        console.log("editform",responseData,editform)
        var editId = $(this).attr("id")
        editform = responseData[editId]
        changePassword.value = false
        showEditDetails.value = ! showEditDetails.value
      });

      function onNewUserInput(phone, phoneObject) {
        console.log("phone",phone,phoneObject)
        if (phoneObject?.formatted) {
          newUserForm.mobile_number = phoneObject.number
        }
      }

      function onEditUserInput(phone, phoneObject) {
        if (phoneObject?.formatted) {
          editform.mobile_number = phoneObject.number
        }
      }
      

      onMounted(() => {
        getUserData()
    })
</script>

<template>

<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true" />
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        
        <DataTableComponent :data="userData"  :columns="columns" :options="options" v-if="!showForm && !showViewDetails && !showEditDetails">
            <template #card_heading>
              <div class="row">
                <div class="col-sm-6">
                  <h3 class="card-head-title">Manage Users</h3>
                </div>
                
                <div class="col-sm-6">
                  <div class="d-flex justify-content-end">
                    <BButton variant="primary" class="md add-user-btn" @click="showForm = !showForm"><font-awesome-icon :icon="['fas', 'user-plus']" /> &nbsp;Add New User</BButton>
                  </div>
                </div>
              </div>
                
                
            </template>
            <template #table_header>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Role</th>
                <th>Status</th>
                <th>Action</th>
                
            </template>
        </DataTableComponent>

        <div class="card settings-card"  v-if="showForm">
            <div class="form-wrapper">
              <BForm @submit="onSubmitUser" @reset="onReset">
                <div class="row">
                  <div class="col-sm-6">
                    <h3 class="card-head-title">Add New User</h3>
                  </div>
                  
                  <div class="col-sm-6">
                    <div class="d-flex justify-content-end">
                      <BButton variant="primary" class="md"  @click="showForm = !showForm">Go Back</BButton>
                    </div>
                  </div>
                </div>

                <div class="row form-row">              

                      <div class="col-lg-6 indv-input">
                          <BFormGroup id="input-group-1" label="User Name" label-for="name" >
                            <BFormInput v-model="newUserForm.username" placeholder="Enter your username"  type="text" size="md"  required />
                          </BFormGroup>
                          
                      </div>
                      <div class="col-lg-6 indv-input">
                          <BFormGroup id="input-group-1" label="Email Address" label-for="email" >
                              <BFormInput v-model="newUserForm.email" placeholder="Enter your email"   type="email" size="md"  required />
                          </BFormGroup>


                      </div>
                      
                </div>

                <div class="row form-row">              

                  <div class="col-lg-4 indv-input">
                      <BFormGroup id="input-group-1" label="Mobile" label-for="name" >
                          <vue-tel-input class="form-control form-control-sm" :value="newUserForm.mobile_number" @input="onNewUserInput" mode="international" :required="true"></vue-tel-input>
                      </BFormGroup>
                      
                  </div>

                  <div class="col-lg-4 indv-input">
                      <BFormGroup id="input-group-1" label="Location" label-for="name" >
                      <BFormInput v-model="newUserForm.location" placeholder="Enter your location"   type="text" size="md"  required />
                      </BFormGroup>
                      
                  </div>

                  <div class="col-lg-4 indv-input">
                      <BFormGroup id="input-group-1" label="Role" label-for="email" >
                          <select v-model="newUserForm.associated_role" class="form-select form-select-md" aria-label="length-select" required>
                              <option selected value='null'>Please Select</option>
                              <option v-for="role in responseData.role_data" :key="role.role_id" :value="role.role_id">{{ role.role_name }}</option>
                          </select>
                      </BFormGroup>
                  </div>

                </div>



                  <div class="row form-row">              

                      <div class="col-lg-6 indv-input">
                          <BFormGroup id="input-group-1" label="Password" label-for="name" >
                          <BFormInput v-model="newUserForm.password" placeholder="Enter password"   type="password" size="md"  required />
                          </BFormGroup>
                          
                      </div>
                      <div class="col-lg-6 indv-input">
                          <BFormGroup id="input-group-1" label="Re-enter Password" label-for="email" >
                          <BFormInput v-model="newUserForm.reEnteredPassword" placeholder="Re-enter password"  type="password" size="md" required />
                          </BFormGroup>
                      </div>

                  </div>

                <div class="row form-row">
                  <BFormGroup id="input-group-1" label="Status:" label-for="email" >
                    <BFormRadioGroup id="radio-group-2" v-model="newUserForm.is_active" name="radio-sub-component" :required="true">
                      <BFormRadio value="true">Active</BFormRadio>
                      <BFormRadio value="false">Inactive</BFormRadio>
                    </BFormRadioGroup>
                  </BFormGroup>
                </div>

                <div class="row">
                  <div class="d-flex justify-content-end"> <BButton class="form-submit-btn" type="submit" variant="primary">Add User</BButton></div>
                </div>

              </BForm>
            </div>
        </div>


        <div class="card settings-card"  v-if="showEditDetails">
            <div class="form-wrapper">
              <BForm @submit="onUpdateUser" @reset="onReset">
              <div class="row">
                <div class="col-sm-6">
                  <h3 class="card-head-title">Edit User</h3>
                </div>
                
                <div class="col-sm-6">
                  <div class="d-flex justify-content-end">
                    <BButton variant="primary" class="md"  @click="showEditDetails = !showEditDetails">Go Back</BButton>
                  </div>
                </div>
              </div>

              <div class="row form-row">              

                    <div class="col-lg-6 indv-input">
                        <BFormGroup id="input-group-1" label="User Name" label-for="name" >
                          <BFormInput   type="text" size="md" placeholder="Enter your username"  required v-model="editform.username"/>
                        </BFormGroup>
                        
                    </div>
                    <div class="col-lg-6 indv-input">
                        <BFormGroup id="input-group-1" label="Email Address" label-for="email" >
                            <BFormInput   type="email" size="md" placeholder="Enter your email" disabled="true" v-model="editform.email"  required />
                        </BFormGroup>


                    </div>
                    
              </div>

              <div class="row form-row">              

                <div class="col-lg-4 indv-input">
                    <BFormGroup id="input-group-1" label="Mobile" label-for="name" >
                        <vue-tel-input class="form-control form-control-sm"  :value="editform.mobile_number" @input="onEditUserInput" mode="international" :required="true"></vue-tel-input>
                    </BFormGroup>
                    
                </div>

                <div class="col-lg-4 indv-input">
                    <BFormGroup id="input-group-1" label="Location" label-for="name" >
                    <BFormInput   type="text" size="md" placeholder="Enter your location"  required  v-model="editform.location"/>
                    </BFormGroup>
                </div>

                <div class="col-lg-4 indv-input">
                    <BFormGroup id="input-group-1" label="Role" label-for="email" >
                        <select v-model="editform.associated_role" class="form-select form-select-md" aria-label="length-select" required>
                            <option value="">Please Select</option>
                            <option v-for="role in responseData.role_data" :selected="editform.associated_role == role.role_id"  :key="role.role_id" :value="role.role_id">{{ role.role_name }}</option>
                        </select>
                    </BFormGroup>
                </div>

              </div>


                <div class="row form-row">              

                    

                </div>

                <div class="row form-row">              

                    <div class="col-lg-6 indv-input" v-if="changePassword">
                        <BFormGroup id="input-group-1" label="Password" label-for="name" >
                        <BFormInput v-model="editform.password" placeholder="Enter password"   type="password" size="md"   />
                        </BFormGroup>
                    </div>
                    <div class="col-lg-6 indv-input" v-if="changePassword">
                        <BFormGroup id="input-group-1" label="Re-enter Password" label-for="email" >
                        <BFormInput v-model="editform.reenteredpassword" placeholder="Re-enter password"  type="password" size="md"  />
                        </BFormGroup>
                    </div>

                    <div class="d-flex justify-content-end" v-if="!changePassword"><BButton class="btn form-btn" @click="changePassword = !changePassword">Change Password</BButton></div>
                </div>

              <div class="row form-row">
                <BFormGroup id="input-group-1" label="Status:" label-for="email" >
                  <BFormRadioGroup id="radio-group-2" v-model="editform.is_active" name="radio-sub-component" :required="true">
                    <BFormRadio  :value=true>Active</BFormRadio>
                    <BFormRadio :value=false>Inactive</BFormRadio>
                  </BFormRadioGroup>
                </BFormGroup>
              </div>

              <div class="row">
                <div class="d-flex justify-content-end"> <BButton class="form-submit-btn" type="submit" variant="primary">Update User</BButton></div>
              </div>
            </BForm>
            </div>
        </div>



        <div class="card settings-card"  v-if="showViewDetails">
          <div class="form-wrapper">
            <div class="row">
                <div class="col-sm-6">
                  <h3 class="card-head-title">User Profile Information</h3>
                </div>
                
                <div class="col-sm-6">
                  <div class="d-flex justify-content-end">
                    <BButton variant="primary" class="md"  @click="showViewDetails = !showViewDetails">Go Back</BButton>
                  </div>
                </div>
            </div>

            <div class="row form-row">
              <div class="col-sm-6">Name: {{ viewForm.username }}</div>
              <div class="col-sm-6">Email: {{ viewForm.email }}</div>
            </div>
            
            <div class="row form-row">
              <div class="col-sm-6">Mobile Number: {{ viewForm.mobile_number }}</div>
              <div class="col-sm-6">Role: {{ viewForm.role_name }}</div>
            </div> 

            <div class="row form-row">
              <div class="col-sm-6">Location: {{ viewForm.location }}</div>
              <div class="col-sm-6">Email Verified: No</div>
            </div> 

            <div class="row form-row">
              <div class="col-sm-6">Status: {{ viewForm.status }}</div>
              
            </div> 

          </div>
        </div>

      </div>

      
    </BCol>
  </BRow>
  
</BContainer>
</template>
