<script setup>

    // const options = {
    //     pagingType: "full_numbers",
    //     lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
    //     responsive: {"details": {"type": 'column',"target": -1}},
    //     ordering: false,
    //     // createdRow: function (row, data, index) {
    //     // $(row).addClass("labelwarning");
    //     // }
    //     // scrollX: true
    //     // processing: true,
    //     // serverSide: true,
    //     // destroy: true,
    // }
    
   
</script>

<template>
        <div class="datatable-card">
            <div class="datatable-card-body">
                <slot name="card_heading">

                </slot>
                <DataTable :data="data" id="data_table"    :options="options" :columns="columns">
                    <thead>
                        <tr>
                            <!-- <th>Machine Name</th>
                            <th>Reported On</th>
                            <th>Event Type</th>
                            <th>Event Data</th> -->
                            <slot name="table_header">

                            </slot>
                        </tr>
                    </thead>
                </DataTable>
            </div>
        </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3';
    import DataTablesCore from 'datatables.net-bs5';

    DataTable.use(DataTablesCore);
    
    

    export default {
        name: 'DataTableComponent',
        components: {

        },
        props:['data', 'columns', 'options'],

    }
    
</script>

<style>
@import 'bootstrap';
@import 'datatables.net-bs5';
</style>