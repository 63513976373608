<script setup>
  import { ref, onMounted, defineProps }  from 'vue';
  var username = ref("None")
  var isLoggedIn = ref(false)
  import { useStore } from 'vuex'
  const store = useStore()
  import router from '@/router';

  const props = defineProps(['sideBarEnable', 'isAdmin'])

  function openNav() {
    document.getElementById("mySidebar").classList.add('sidebar-expand')
    document.getElementById("main").classList.add('main-expand');
    document.getElementById("open-nav-btn").style.display = "none"
    document.getElementById("close-nav-btn").style.display = "block"
  }

  function closeNav() {
    document.getElementById("mySidebar").classList.remove('sidebar-expand');
    document.getElementById("main").classList.remove('main-expand');
    document.getElementById("close-nav-btn").style.display = "none"
    document.getElementById("open-nav-btn").style.display = "block"
  }

  async function logout () {
    await store.dispatch('logOut');
    if(props.isAdmin){
      router.push({ name: 'AdminLogin'})
    }
    else{
      router.push({ name: 'Home'})
    }
    
  }


  async function isConnected() {
    try{
      if(props.isAdmin){
        await store.dispatch('adminViewMe')
      }else{
        await store.dispatch('viewMe')
      }
      
      if(store.getters.isAuthenticated){
        console.log("loggen in this")
        username.value = store.getters.stateUser.username
      }

      isLoggedIn.value = store.getters.isAuthenticated
      return store.getters.isAuthenticated
    }catch(err) {
      console.log("error in catch:",err)
      console.log("here1",store.getters.isAuthenticated)

      if(props.isAdmin){
        router.push({ name: 'AdminLogin'})
      }
      else{
        router.push({ name: 'Home'})
      }
    }
  }

  
  

  // var isLoggedIn = computed(() => {
  //   if(this.$store.getters.isAuthenticated){
  //     console.log("loggen in successfully")
  //     username.value = this.$store.getters.stateUser.username
  //   }
  //   return this.$store.getters.isAuthenticated
  // })

  onMounted(async () => {
    await isConnected();
  })

</script>


<template>


    <BNavbar class="shadow p-0 bg-body top-navbar navbar-expand-sm" fixed="top" toggleable="lg" v-b-color-mode="'light'">
      <BNavbarBrand href="#" class="nav-logo"><img class="header__logo" src="../assets/images/logo-inverse.png" alt = "Maxcess Logo"></BNavbarBrand>
      <div class="sidebar-toggle">
        <button v-if="sideBarEnable" class="closebtn" id="close-nav-btn" @click="closeNav">X</button>
        <button v-if="sideBarEnable" class="openbtn" id="open-nav-btn"  @click="openNav" style="display: none;">☰</button>  
      </div>
      
      <BNavbarToggle v-if="sideBarEnable" target="nav-collapse-topnav" />
      <BCollapse v-if="sideBarEnable" id="nav-collapse-topnav" is-nav>
        <!-- Right aligned nav items -->
        <BNavbarNav v-if="isLoggedIn" class="ms-auto navbar-right-items">
          <div class="vr m-3 me-3"></div>
          <BNavText class="p-3"><b>{{ username }}</b></BNavText>
          <button @click="logout" class="logout-btn btn m-2"><font-awesome-icon :icon="['fas', 'right-from-bracket']" style="color: #fcfcfc;" /></button>
        </BNavbarNav>
      </BCollapse>
    </BNavbar>
  
</template>


