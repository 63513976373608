<script setup>

    import { ref, onMounted, nextTick } from 'vue';
    import axios from 'axios';
    import { useStore } from 'vuex'
    const store = useStore()
    import router from '@/router';

    var eventSettings = ref(null)
    var userResponseData = ref([])
    var selectedUser = ref(null)

    const show = ref(true)
    const onSubmit = (event) => {
        event.preventDefault()
        console.log("submitted",eventSettings.value)
        updateEventSettings();
        // alert(JSON.stringify(form))
    }
    
    const onReset = (event) => {
        event.preventDefault()
        // Reset our form values
        // form.email = ''
        // form.name = ''
        // Trick to reset/clear native browser form validation state
        show.value = false
        nextTick(() => {
            show.value = true
        })
    }

    async function getUserDropdownData(){
        var clientId = store.getters.stateUser.associated_client
        if(clientId != null){
            await axios.post('get_client_users_dropdown', { "client_id": clientId})
            .then(response => (
                console.log("response in get_client_users_dropdown",response),
                userResponseData.value = response.data
            )
            )
        }
        else{
            router.push({ name: 'Home'})
        }
    }


    async function getEventSettings(){
        if(store.getters.stateUser != null){
            var data = store.getters.stateUser
            data.selected_user = selectedUser.value
            console.log("data here",data)
            await axios.post('get_event_notification_settings', data)
            .then(response => (
                console.log("response in get_event_notification_settings",response),
                eventSettings.value = response.data
            )
            )
        }
        else{
            router.push({ name: 'Home'})
        }
    }

    async function updateEventSettings(){
        var clientId = store.getters.stateUser.associated_client
        var userId
        if(selectedUser.value != null){
            userId = selectedUser.value
        }else{
            userId = store.getters.stateUser.id
        }
        
        if(clientId != null && userId!= null){
            await axios.post('update_event_notification_settings', { "event_settings": eventSettings.value, "client_id": clientId, "user_id": userId })
            .then(response => (
                console.log("response in update_event_notification_settings",response)
            )
            )
        }
        else{
            router.push({ name: 'Home'})
        }
    }
    
    onMounted(async () => {
        await getUserDropdownData();
        await getEventSettings()
    })
    
</script>

<template>

<BContainer fluid>
  <BRow>
    <NavbarComponent :sideBarEnable="true"/>
  </BRow>
  <BRow>
    <BCol>
      <SidebarComponent />

      <div class="main-expand" id="main">
        <div class="card settings-card">
            <div class="form-wrapper">
                
                <BForm @submit="onSubmit" @reset="onReset">
                <div class="container-fluid">

                    <h4>Event Type Settings</h4>

                    <div class="row form-row">
                        
                        <div class="col-lg-5 es-select">
                            <select @change="getEventSettings" v-model="selectedUser" class="form-select form-select-sm" aria-label="length-select">
                                    <option selected :value="null">Select User</option>
                                    <option v-for="user in userResponseData" :key="user.id" :value="user.id">{{ user.username }}</option>
                            </select>
                        </div>
                    </div>


                    <div class="row es-table-wrapper" id="es-table">
                        <BTableSimple>
                            <BThead head-variant="dark">
                                <BTr>
                                    <BTh colspan="3">Event Type</BTh>
                                    <BTh colspan="2">Text Message</BTh>
                                    <BTh colspan="2">Email</BTh>
                                </BTr>
                            </BThead>
                            <BTbody>
                                <BTr v-for="ev in eventSettings" :key="ev.event_name">
                                    <BTd colspan="3" :variant="[ev.event_priority == 'High' ? 'danger' : ev.event_priority == 'Moderate' ? 'warning' : ev.event_priority == 'Low' ? 'success' : '']" >{{ ev.event_name }}</BTd>
                                    <BTd colspan="2" :variant="[ev.event_priority == 'High' ? 'danger' : ev.event_priority == 'Moderate' ? 'warning' : ev.event_priority == 'Low' ? 'success' : '']" ><BFormCheckbox v-model="ev.sms_alert" :checked="ev.sms_alert" switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" :variant="[ev.event_priority == 'High' ? 'danger' : ev.event_priority == 'Moderate' ? 'warning' : ev.event_priority == 'Low' ? 'success' : '']" ><BFormCheckbox v-model="ev.email_alert" :checked="ev.email_alert" switch></BFormCheckbox></BTd>
                                </BTr>
                                <!-- <BTr>
                                    <BTd colspan="3" variant="danger">Under Pressure</BTd>
                                    <BTd colspan="2" variant="danger"><BFormCheckbox switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" variant="danger"><BFormCheckbox switch></BFormCheckbox></BTd>
                                </BTr>
                                
                                <BTr>
                                    <BTd colspan="3" variant="danger">Anvil Pressure Change</BTd>
                                    <BTd colspan="2" variant="danger"><BFormCheckbox switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" variant="danger"><BFormCheckbox switch></BFormCheckbox></BTd>
                                </BTr>
                               
                                <BTr>
                                    <BTd colspan="3" variant="danger">Adjustments at Run Speed</BTd>
                                    <BTd colspan="2" variant="danger"><BFormCheckbox switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" variant="danger"><BFormCheckbox switch></BFormCheckbox></BTd>
                                </BTr>
                                <BTr>
                                    <BTd colspan="3" variant="warning">Adjustments</BTd>
                                    <BTd colspan="2" variant="warning"><BFormCheckbox switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" variant="warning"><BFormCheckbox switch></BFormCheckbox></BTd>
                                </BTr>
                                <BTr>
                                    <BTd colspan="3" variant="warning">Unknown Die Usage</BTd>
                                    <BTd colspan="2" variant="warning"><BFormCheckbox switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" variant="warning"><BFormCheckbox switch></BFormCheckbox></BTd>
                                </BTr>
                                <BTr>
                                    <BTd colspan="3" variant="warning">Job Start</BTd>
                                    <BTd colspan="2" variant="warning"><BFormCheckbox switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" variant="warning"><BFormCheckbox switch></BFormCheckbox></BTd>
                                </BTr>
                                <BTr>
                                    <BTd colspan="3" variant="warning">Job End</BTd>
                                    <BTd colspan="2" variant="warning"><BFormCheckbox switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" variant="warning"><BFormCheckbox switch></BFormCheckbox></BTd>
                                </BTr>
                                <BTr>
                                    <BTd colspan="3" variant="success">Impression Change</BTd>
                                    <BTd colspan="2" variant="success"><BFormCheckbox switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" variant="success"><BFormCheckbox switch></BFormCheckbox></BTd>
                                </BTr>
                                
                                <BTr>
                                    <BTd colspan="3" variant="success">Pressure Difference</BTd>
                                    <BTd colspan="2" variant="success"><BFormCheckbox switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" variant="success"><BFormCheckbox switch></BFormCheckbox></BTd>
                                </BTr>
                                <BTr>
                                    <BTd colspan="3" variant="success">Manual Mode</BTd>
                                    <BTd colspan="2" variant="success"><BFormCheckbox switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" variant="success"><BFormCheckbox switch></BFormCheckbox></BTd>
                                </BTr>
                                
                                <BTr>
                                    <BTd colspan="3" variant="success">Maintainance Alert</BTd>
                                    <BTd colspan="2" variant="success"><BFormCheckbox switch></BFormCheckbox></BTd>
                                    <BTd colspan="2" variant="success"><BFormCheckbox switch></BFormCheckbox></BTd>
                                </BTr> -->
                            </BTbody>
                        </BTableSimple>
                    </div>
                    
                    

                    <div class="sumbit-btn-wrapper">
                        <BButton class="btn clear-btn"  variant="secondary">Clear All</BButton>
                        <BButton class="form-submit-btn" type="submit" variant="primary">Submit</BButton>
                    </div>
                    </div>
                    
                </BForm>
            </div>
            

        </div>
        
      </div>
    </BCol>
  </BRow>
  
</BContainer>
</template>

<script>
    import NavbarComponent from '../components/NavbarComponent.vue'
    import SidebarComponent from '../components/SidebarComponent.vue'


    export default {
        name: 'EventSettingsView',
        components: {
            SidebarComponent,
            NavbarComponent
            // HelloWorld
        }
    }
</script>